var iterableOptinForm = document.getElementById('iterable-optin');

if (iterableOptinForm) {
	const emailInput = document.getElementById('signup-email');
	const submitButton = document.getElementById('iterable-submit-btn');
	const form = document.getElementById('iterable-optin');

	emailInput.addEventListener('input', (event) => {
		const value = event.target.value;
		if (value) {
			submitButton.hasAttribute('disabled') &&
				submitButton.removeAttribute('disabled');
		} else {
			submitButton.setAttribute('disabled', '');
		}
	});

	const observer = new MutationObserver(function(mutationsList) {
		if (Array.isArray(mutationsList) && mutationsList.length > 0) {
			const mutationNode = mutationsList[0];
			if (mutationNode.removedNodes.length === 5) {
				form.innerHTML = `
				<h3 class="f-medium">Got it – we'll keep in touch!</h3>
			`;
				form.className = 'form--submitted';
			}
		}
	});

	observer.observe(form, {
		characterData: false,
		childList: true,
		attributes: false,
	});
}
